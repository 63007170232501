import { API_URL } from './endpoints'

const credentials = {
  'dev-web': {
    client_id: 'fczuQsTwfOqgd_kG_KkXosOqO7eUpmtWjbt1JnnujUM',
    client_secret: 'ng25EKXJzZh43NDT6zHYcz_ogjhY8HMh44TnT1i3OnA',
  },
  'pony-web': {
    client_id: 'fczuQsTwfOqgd_kG_KkXosOqO7eUpmtWjbt1JnnujUM',
    client_secret: 'ng25EKXJzZh43NDT6zHYcz_ogjhY8HMh44TnT1i3OnA',
  },
  'valid-web': {
    client_id: 'fczuQsTwfOqgd_kG_KkXosOqO7eUpmtWjbt1JnnujUM',
    client_secret: 'ng25EKXJzZh43NDT6zHYcz_ogjhY8HMh44TnT1i3OnA',
  },
  localhost: {
    client_id: 'fczuQsTwfOqgd_kG_KkXosOqO7eUpmtWjbt1JnnujUM',
    client_secret: 'ng25EKXJzZh43NDT6zHYcz_ogjhY8HMh44TnT1i3OnA',
  },
  'mezzo-web': {
    client_id: 'qeThOttq06zY6_jdZ091igKnHWKds5HEQZM1MIDFbhs',
    client_secret: '3OKHh1WE8BONcqJ_RsA2Hdz63cpUgiY0pBejnoZ0n6U',
  },
  'smartlink-web': {
    client_id: 'CrcdG869bHyd0jv23W_IMKi8Dg9aH3PKe0OYLWsHQgQ',
    client_secret: 'Dy_VDXPH0lpHIAjVXpKm8iHFdWuLEFPkRASn9Z5NEmQ',
  },
  'lendlease-web': {
    client_id: 'CrcdG869bHyd0jv23W_IMKi8Dg9aH3PKe0OYLWsHQgQ',
    client_secret: 'Dy_VDXPH0lpHIAjVXpKm8iHFdWuLEFPkRASn9Z5NEmQ',
  },
  'ocelot-web': {
    client_id: 'fczuQsTwfOqgd_kG_KkXosOqO7eUpmtWjbt1JnnujUM',
    client_secret: 'ng25EKXJzZh43NDT6zHYcz_ogjhY8HMh44TnT1i3OnA',
  },
}

const getCredentials = (host = window.location.hostname) => {
  const [subdomain] = host.split('.')
  return credentials[subdomain]
}

export const userCredentials = {
  ...getCredentials(),
  scope: 'adminPanel',
  grant_type: 'password',
}

export const AUTH_ENDPOINT = `${API_URL}/oauth/token`
export const TEMPORARY_AUTH_ENDPOINT = `${API_URL}/oauth/token?from_provider=true`

export const TIMEZONE_NUMBER_DEFINITION = 13
export const STATE_LIST_LIMIT_MIN = 10
export const STATE_LIST_LIMIT_MEDIUM = 20
export const STATE_LIST_LIMIT_MAX = 30

export const DEFAULT_ITEMS_LIMIT = '10'
export const DEFAULT_PAGE = '1'
